<template>
  <div>
    <div class="topbg"></div>
    <div class="w_auto">
      <div class="navbar">当前位置：<span class="navtitle" @click="goIndex">首页</span> > <span class="articleName">{{ detailList.articleName
          }}</span></div>
      <div class="container">
        <el-container>
          <!-- <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
            <el-aside width="240px">
              <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" :unique-opened="true">
                <template v-for="(item, index) in nav">
                  <el-submenu :index="item.menuId" :key="item.menuId">
                    <template slot="title"><span
                        @click="menuShow(index + 1, item.navigationType, item.moduleCode, item.menuId)">{{ item.menuName
                        }}</span></template>
                    <el-menu-item v-for="(subItem) in item.subMenu" :index="subItem.menuId" :key="subItem.menuId"
                      @click="selectAritcleByTypeId(subItem.typeId)">
                      {{ subItem.typeName }}
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-menu>
            </el-aside>
          </el-aside> -->
          <el-main>
            <div class="allist mb50">
              <div class="shadow radius5 bgwhite pad10">
                <h2>{{ detailList.articleName }}</h2>
                <div class="release-info borderbot">
                  <span><b>发布人：</b>{{ detailList.authorName }}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span><b>阅读量：</b>{{ detailList.checknum }}</span>
                </div>
                <div class="newscont pad20">
                  <p v-html="detailList.content"></p>
                </div>
                <div class="pd20 cor4">
                  <span><i class="el-icon-time"></i><b style="font-weight: 400">发布时间：</b>
                    {{ detailList.createdat.slice(0, 10) }}
                  </span>
                </div>
              </div>
            </div>
          </el-main>
        </el-container>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/time.js";
export default {
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      detailList: [],
      nav: [],
    };
  },
  mounted() {
    this.getArticleInfo();
    this.getAppNavigationMenuApi()
  },
  methods: {
    menuShow(index, type, code, menuId) {
      console.log(2131231, index, type, code, menuId)
      this.menuIndex = index;
      if (type == 1 && code == "1") {
        this.$router.push({
          path: "/news/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "3") {
        this.$router.push({
          path: "/course/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "4") {
        this.$router.push({
          path: "/live/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "2") {
        this.$router.push({
          path: "/activity/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "5") {
        this.$router.push({
          path: "/meet/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "-1") {
        this.$router.push({
          path: "/index",
        });
      }
      this.defaultActive = null
      this.getAppNavigationMenuApi()
    },
    getAppNavigationMenuApi() {
      this.$api
        .getAppNavigationMenuApi({
          channelId: this.$http.channelId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            res.data.unshift({menuName:'中心首页',navigationType:1,moduleCode:'-1',menuId:'0000'})
            res.data.map(i => {
              i.subMenu = []
            })
            this.nav = res.data;
            res.data.map(i=>{
              if(i.menuId == this.$route.query.id){
                this.getArticleTypeListApi(i.typeId);
              }
            })
          }
        });
    },
    getArticleInfo() {
      this.$api
        .getChannelArticleInfoApi({
          token: this.token,
          articleId: this.$route.query.id,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.detailList = res.data;
          }
        });
    },
    goIndex(){
      this.$router.push({
          path: "/index",
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.mt15 {
  margin-top: 15px;
}

.mb50 {
  margin-bottom: 50px;
}

.pad10 {
  padding: 10px 5px;
}

.shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.radius5 {
  border-radius: 5px;
}

.bgwhite {
  display: inline-block;
  background-color: #fff;
  width: 100%;
}

.allist h2 {
  padding: 40px 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 21px;
}

.release-info {
  padding: 20px;
  text-align: right;
}

.borderbot {
  border-bottom: 2px solid #f7f7f7;
}

.release-info span {
  color: #666;
  font-size: 14px;
}

.release-info span b {
  color: #000;
  font-weight: 400;
}

.newscont {
  text-indent: 20px;
  line-height: 30px;
  font-size: 14px;
}

.newscont p {
  margin-bottom: 15px;
  color: #333;
}

.pad20 {
  padding: 20px;
}

.cor4 {
  padding: 20px;
  color: #444;
  text-align: right;
  font-size: 14px;
}

.navbar {
  width: 100%;
  background: #fff;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  margin-top: 30px;
}

.navtitle {
  color: #007800;
  cursor: pointer;
}

.articleName {
  color: #000
}

.container {
  ::v-deep .el-container {
    margin-top: 10px;
  }

  ::v-deep .el-main {
    padding: 0px
  }

  ::v-deep {
    .el-submenu__title {
      background: rgb(1, 121, 0);
      border-left: 5px solid rgb(143, 197, 62);
      color: #fff;
      font-size: 15px;
    }
  }
}

.topbg {
  background-image: url('../../assets/images/home/topbg.png');
  background-size: 100% 100%;
  width: 100%;
  height: 365px;
}
</style>